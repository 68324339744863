<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <div>
          <b-embed type="iframe" aspect="16by9" :src="iframeUrl" allowfullscreen />
        </div>
      </b-card>
      <b-card v-if="secondIframeUrl">
        <div>
          <b-embed type="iframe" aspect="16by9" :src="secondIframeUrl" allowfullscreen />
        </div>
      </b-card>
      <b-card v-if="thirdIframeUrl">
        <div>
          <b-embed type="iframe" aspect="16by9" :src="thirdIframeUrl" allowfullscreen />
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BEmbed,
} from 'bootstrap-vue'
// import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import 'vue-loading-overlay/dist/vue-loading.css'
// import App from '../../main'
// import UserDropdown from '../../@core/layouts/components/app-navbar/components/UserDropdown.vue'
// import { isUserLoggedIn } from '@/auth/utils'
// import { canNavigate } from '@/libs/acl/routeProtection'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BEmbed,
  },
  data() {
    return {
      isLoadingNumberOfVolunteers: true,
      isLoadingLowestAttendance: true,
      fullPage: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'NIJ',
          field: 'nij',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Church',
          field: 'church',
        },
        {
          label: 'CG Position',
          field: 'people_position',
        },
        {
          label: 'Ministry',
          field: 'ministry',
        },
        {
          label: 'Result',
          field: 'result',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      searchTerm2: '',
      isRendered: false,
      totalGmsCounts: [],
      options: {
        plotOptions: {
          bar: {
            columnWidth: '30%',
            distributed: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
      },
      iframeUrl: '',
      secondIframeUrl: '',
      thirdIframeUrl: '',
    }
  },
  async mounted() {
    // Get the user details
    const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))

    // Check for main api
    let userDetails = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/user/${userData[0].id}?token=${process.env.VUE_APP_API_TOKEN}`)
    // If not exists
    if (userDetails.data.result.person_id === '') {
      userDetails = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/tlcoach/church/${userData[0].parent_couchdb_id}?token=${process.env.VUE_APP_API_TOKEN}`)
    }
    console.log(userDetails)
    this.iframeUrl = userDetails.data.result.iframe_url
    this.secondIframeUrl = userDetails.data.result.second_iframe_url
    this.thirdIframeUrl = userDetails.data.result.third_iframe_url
    // Set data
    // const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
    // const ministries = userData[0].departments.map(elem => elem).join(',')
    // const churches = userData[0].churches.map(elem => elem).join(',')

    // const config = {
    //   onUploadProgress: function(progressEvent) {
    //     console.log(progressEvent)
    //     // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
    //   }
    // };

    // Get the data
    // this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/dashboard/${ministries}/${churches}`)
    //   .then(response => {
    //     this.isLoadingNumberOfVolunteers = false
    //     this.totalGmsCounts = response.data
    //   })

    // // Get the volunteers CG Presence
    // this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/volunteers/${ministries}/${churches}?page=0&perpage=0&search=`)
    //   .then(response => {
    //     this.isLoadingLowestAttendance = false
    //     this.rows = response.data.rows
    // })
  },
  // methods: {
  //   cgPresenceResult(result) {
  //     let badge = 'badge-danger'
  //     if (result >= 81.25) {
  //       badge = 'badge-success'
  //     } else if (result >= 56.25) {
  //       badge = 'badge-info'
  //     } else if (result >= 31.25) {
  //       badge = 'badge-warning'
  //     }

  //     return badge
  //   },
  // },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>